import { AsyncExport } from "../models/async-export";

export const stub: AsyncExport[] = [
  {
    name: "azure_asset-inventory_inventory_2024-12-19T09_51_05",
    report_url_list: [
      "gs://vcs-v2-reports-prd/2024/12/19/azure_asset-inventory_inventory_2024-12-19T09_51_05_14W4A.csv",
    ],
    created_at: "2024-12-19T09:51:05.488355+00:00",
    updated_at: "2024-12-19T09:51:12.683008+00:00",
    status: "completed",
    cloud_provider: "azure",
    section: "asset-inventory",
    page: "inventory",
    url: "/vcloudsmart/v2/azure/asset-inventory/inventory",
  },
  {
    name: "aws_asset-inventory_s3-storage-buckets_2024-12-19T09_35_41",
    report_url_list: [],
    created_at: "2024-12-19T09:35:41.306572+00:00",
    updated_at: "2024-12-19T09:35:41.490615+00:00",
    status: "failed",
    cloud_provider: "aws",
    section: "asset-inventory",
    page: "s3-storage-buckets",
    url: "/vcloudsmart/v2/aws/asset-inventory/s3-storage-buckets",
  },
  {
    name: "gcp_asset-inventory_overview_2024-12-19T14_54_09",
    report_url_list: [
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000000.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000001.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000002.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000003.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000004.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000005.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000006.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000007.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000008.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000009.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000010.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000011.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000012.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000013.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000014.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000015.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000016.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000017.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000018.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000019.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000020.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000021.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000022.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000023.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000024.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000025.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000026.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000027.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000028.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000029.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000030.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000031.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000032.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000033.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000034.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000035.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000036.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000037.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000038.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000039.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000040.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000041.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000042.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000043.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000044.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000045.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000046.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000047.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000048.csv.gz",
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_overview_2024-12-19T14_54_09_CwshV000000000049.csv.gz",
    ],
    created_at: "2024-12-19T14:54:09.649953+00:00",
    updated_at: "2024-12-19T14:54:22.791179+00:00",
    status: "completed",
    cloud_provider: "gcp",
    section: "asset-inventory",
    page: "overview",
    url: "/vcloudsmart/v2/gcp/asset-inventory/overview",
  },
  {
    name: "gcp_asset-inventory_compute-instances_2024-12-19T10_12_20",
    report_url_list: [
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_asset-inventory_compute-instances_2024-12-19T10_12_20_ZskVU.csv",
    ],
    created_at: "2024-12-19T10:12:20.984689+00:00",
    updated_at: "2024-12-19T10:12:30.738236+00:00",
    status: "completed",
    cloud_provider: "gcp",
    section: "asset-inventory",
    page: "compute-instances",
    url: "/vcloudsmart/v2/gcp/asset-inventory/compute-instances",
  },
  {
    name: "aws_asset-inventory_s3-storage-buckets_2024-12-19T14_50_54",
    report_url_list: [
      "gs://vcs-v2-reports-prd/2024/12/19/aws_asset-inventory_s3-storage-buckets_2024-12-19T14_50_54_h4hF3.csv",
    ],
    created_at: "2024-12-19T14:50:54.254010+00:00",
    updated_at: "2024-12-19T14:51:04.817239+00:00",
    status: "completed",
    cloud_provider: "aws",
    section: "asset-inventory",
    page: "s3-storage-buckets",
    url: "/vcloudsmart/v2/aws/asset-inventory/s3-storage-buckets",
  },
  {
    name: "gcp_sams-vams_sams-report_2024-12-19T10_14_33",
    report_url_list: ["gs://vcs-v2-reports-prd/2024/12/19/gcp_sams-vams_sams-report_2024-12-19T10_14_33_HTolE.csv"],
    created_at: "2024-12-19T10:14:33.275805+00:00",
    updated_at: "2024-12-19T10:14:52.773179+00:00",
    status: "completed",
    cloud_provider: "gcp",
    section: "sams-vams",
    page: "sams-report",
    url: "/vcloudsmart/v2/gcp/sams-vams/sams-report",
  },
  {
    name: "gcp_asset-inventory_overview_2024-12-19T10_02_02",
    report_url_list: [],
    created_at: "2024-12-19T10:02:02.944151+00:00",
    updated_at: "2024-12-19T10:02:03.574206+00:00",
    status: "failed",
    cloud_provider: "gcp",
    section: "asset-inventory",
    page: "overview",
    url: "/vcloudsmart/v2/gcp/asset-inventory/overview",
  },
  {
    name: "aws_asset-inventory_inventory_2024-12-19T14_44_10",
    report_url_list: ["gs://vcs-v2-reports-prd/2024/12/19/aws_asset-inventory_inventory_2024-12-19T14_44_10_XUmtD.csv"],
    created_at: "2024-12-19T14:44:10.964923+00:00",
    updated_at: "2024-12-19T14:45:11.962341+00:00",
    status: "completed",
    cloud_provider: "aws",
    section: "asset-inventory",
    page: "inventory",
    url: "/vcloudsmart/v2/aws/asset-inventory/inventory",
  },
  {
    name: "gcp_cloud-recommendations_overview_2024-12-19T10_15_39",
    report_url_list: [
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_cloud-recommendations_overview_2024-12-19T10_15_39_RAabN.csv",
    ],
    created_at: "2024-12-19T10:15:39.979385+00:00",
    updated_at: "2024-12-19T10:15:46.796576+00:00",
    status: "completed",
    cloud_provider: "gcp",
    section: "cloud-recommendations",
    page: "overview",
    url: "/vcloudsmart/v2/gcp/cloud-recommendations/overview",
  },
  {
    name: "aws_asset-inventory_inventory_2024-12-19T09_24_44",
    report_url_list: ["gs://vcs-v2-reports-prd/2024/12/19/aws_asset-inventory_inventory_2024-12-19T09_24_44_iRBpR.csv"],
    created_at: "2024-12-19T09:24:44.502367+00:00",
    updated_at: "2024-12-19T09:25:41.111293+00:00",
    status: "completed",
    cloud_provider: "aws",
    section: "asset-inventory",
    page: "inventory",
    url: "/vcloudsmart/v2/aws/asset-inventory/inventory",
  },
  {
    name: "aws_asset-inventory_inventory_2024-12-19T09_23_24",
    report_url_list: [],
    created_at: "2024-12-19T09:23:24.425972+00:00",
    updated_at: "2024-12-19T09:23:25.146684+00:00",
    status: "failed",
    cloud_provider: "aws",
    section: "asset-inventory",
    page: "inventory",
    url: "/vcloudsmart/v2/aws/asset-inventory/inventory",
  },
  {
    name: "gcp_cost-and-billing_cost-by-compute-instance-runtime_2024-12-19T10_11_30",
    report_url_list: [
      "gs://vcs-v2-reports-prd/2024/12/19/gcp_cost-and-billing_cost-by-compute-instance-runtime_2024-12-19T10_11_30_5873s.csv",
    ],
    created_at: "2024-12-19T10:11:30.780239+00:00",
    updated_at: "2024-12-19T10:11:46.661609+00:00",
    status: "completed",
    cloud_provider: "gcp",
    section: "cost-and-billing",
    page: "cost-by-compute-instance-runtime",
    url: "/vcloudsmart/v2/gcp/cost-and-billing/cost-by-compute-instance-runtime",
  },
  {
    name: "aws_asset-inventory_inventory_2024-12-19T14_49_50",
    report_url_list: ["gs://vcs-v2-reports-prd/2024/12/19/aws_asset-inventory_inventory_2024-12-19T14_49_50_XRLem.csv"],
    created_at: "2024-12-19T14:49:50.561828+00:00",
    updated_at: "2024-12-19T14:50:00.146491+00:00",
    status: "completed",
    cloud_provider: "aws",
    section: "asset-inventory",
    page: "inventory",
    url: "/vcloudsmart/v2/aws/asset-inventory/inventory",
  },
  {
    name: "azure_other_sku-discount_2024-12-19T09_56_36",
    report_url_list: ["gs://vcs-v2-reports-prd/2024/12/19/azure_other_sku-discount_2024-12-19T09_56_36_qhU71.csv"],
    created_at: "2024-12-19T09:56:36.281392+00:00",
    updated_at: "2024-12-19T09:57:04.448645+00:00",
    status: "completed",
    cloud_provider: "azure",
    section: "other",
    page: "sku-discount",
    url: "/vcloudsmart/v2/azure/other/sku-discount",
  },
];
export const REGEX_NAME = /[-_]?[0-9]{4}[^0-9]*[0-9]{2}[^0-9]*[0-9]{2}T[-_0-9]*/;
export const REGEX_DELIM = /[-_]+/g;
export const DASH_REGEX = /-/g;
export const MAX_REPORTS_TO_SHOW = 5;
