import { Injectable } from "@angular/core";
import { ServiceCloudProvider } from "../../shared/models/services";
import { TenancyPage } from "../../shared/models/tenancies";
import { Csp } from "../../shared/models/vcloudsmart";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HomeService {
  public services: Array<ServiceCloudProvider> = [];
  public tenancyPages: Array<TenancyPage> = [];
  public vcsConfig: Array<Csp> = [];
  public vcsModifiedConfig: Array<Csp> = [];
  private selectedTabSubject = new BehaviorSubject<string>("");
  public selectedTab$ = this.selectedTabSubject.asObservable();
  public setSelectedTab(tab: string) {
    this.selectedTabSubject.next(tab);
  }
}
