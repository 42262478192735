import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { forkJoin, from, Observable, of, throwError } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AuthenticationService } from "./authentication.service";
import { catchError, delay, map, mergeMap, tap } from "rxjs/operators";
import { LoggerService } from "./logger.service";
import { PcsApiService } from "./pcs-api.service";
import { AlertsService } from "./alerts.service";
import Swal from "sweetalert2";
import Decimal from "decimal.js";
import {
  CloudProvider,
  FilterableL1,
  FilterableL2,
  FiltersType,
  GroupByApiResult,
  GroupByPayload,
  Level,
  QueryFilterSimple,
  QueryMetadata,
} from "../models/vcloud-api";
import { Filter, FilterValue } from "../models/filtering";
import { QUERY_COST_CURRENCY, QUERY_COST_THRESHOLD } from "../constant/queries";
import { MAX_API_RESPONSE_LIMIT, OPERATOR_SCORES, ROWS_PER_PAGE } from "../constant/vcloud-api";
import { CSP } from "../models/csp-api";
import { MAX_EXPORT_DURATION, MAX_EXPORT_PARALLELISM } from "../constant/export";

const SECOND = 1000;

@Injectable({
  providedIn: "root",
})
export class VCloudSmartApiService {
  public readonly baseUrl = environment.urlVcloudApi;

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    private alerts: AlertsService,
    private log: LoggerService,
    private pcsApi: PcsApiService
  ) {}

  // public clearCapturedResponses(): void {
  //   this.capturedResponses = {};
  // }

  public processAsyncDownloadRequestBody(body: GroupByPayload): GroupByPayload {
    const clone: GroupByPayload = JSON.parse(JSON.stringify(body));
    // for (const p of clone?.Projection ?? []) {
    //   if (p?.Operation !== undefined) {
    //     delete p.Operation;
    //   }
    //   if (p?.OperationArgs !== undefined) {
    //     delete p.OperationArgs;
    //   }
    // }
    if (clone?.Limit !== undefined) {
      delete clone.Limit;
    }
    if (clone?.Offset !== undefined) {
      delete clone.Limit;
    }
    if (clone?.OrderBy !== undefined) {
      delete clone.OrderBy;
    }
    if (clone?.SortOrder !== undefined) {
      delete clone.SortOrder;
    }
    return clone;
  }

  public getLevelsForMetadata(csp: CSP): Level[] {
    const o: { [csp: string]: { id: string; label?: string }[] } = {
      aws: [
        {
          id: "account_id",
        },
        {
          id: "account_name",
        },
        {
          id: "status",
          label: "Account Status",
        },
        {
          id: "local_market",
          label: "Master Account",
        },
        {
          id: "managed_by",
        },
        {
          id: "business_service",
        },
        {
          id: "environment",
        },
        {
          id: "vams_id",
        },
        {
          id: "business_email",
        },
        {
          id: "confidentiality",
        },
        {
          id: "technical_email",
        },
        {
          id: "sec_assessment",
          label: "Security Assessment ID",
        },
      ],
      gcp: [
        {
          id: "project_id",
        },
        {
          id: "po_number",
        },
        {
          id: "project_name",
        },
        {
          id: "project_number",
        },
        {
          id: "business_service",
        },
        {
          id: "cost_centre",
        },
        {
          id: "wbs",
        },
        {
          id: "programme",
        },
        {
          id: "sec_assessment",
          label: "Security Assessment ID",
        },
        {
          id: "project_owner",
          label: "Business Owner",
        },
        {
          id: "data_governance_compliance_manager",
          label: "Data Governance & Compliance Manager",
        },
        {
          id: "managed_by",
        },
        {
          id: "confidentiality",
        },
        {
          id: "environment",
        },
        {
          id: "local_market",
        },
        {
          id: "budget_owner",
          label: "Cost Centre Owner",
        },
        {
          id: "status",
          label: "Project Status",
        },
        {
          id: "bu",
          label: "Business Unit",
        },
      ],
      oci: [
        {
          id: "compartment_name",
        },
        {
          id: "compartment_id",
        },
        {
          id: "compartment_status",
        },
        {
          id: "parent_id",
        },
        {
          id: "parent_type",
        },
        {
          id: "parent_type",
        },
        {
          id: "budget_owner",
        },
        {
          id: "compartment_owner",
        },
        {
          id: "programme",
        },
        {
          id: "ownership_type",
        },
        {
          id: "technical_owner",
        },
        {
          id: "confidentiality",
        },
        {
          id: "cost_centre",
        },
        {
          id: "service_class",
        },
        {
          id: "portfolio",
        },
        {
          id: "managed_by",
        },
        {
          id: "local_market",
        },
      ],
      drcc: [
        {
          id: "compartment_name",
        },
        {
          id: "compartment_id",
        },
        {
          id: "compartment_description",
        },
        {
          id: "tenant_name",
        },
        {
          id: "tenant_id",
        },
        {
          id: "budget_owner",
        },
        {
          id: "created_by",
        },
        {
          id: "home_region",
        },
        {
          id: "compartment_status",
        },
        {
          id: "parent_name",
        },
        {
          id: "parent_id",
        },
        {
          id: "business_service",
        },
        {
          id: "compartment_owner",
        },
        {
          id: "confidentiality",
        },
        {
          id: "compartment_type",
        },
        {
          id: "environment",
        },
        {
          id: "local_market",
        },
        {
          id: "managed_by",
        },
        {
          id: "portfolio",
        },
        {
          id: "programme",
        },
        {
          id: "service_class",
        },
        {
          id: "technical_owner",
        },
        {
          id: "compartment_category",
        },
        {
          id: "request_id",
        },
      ],
      azure: [
        {
          id: "programme",
        },
        {
          id: "cost_centre",
        },
        {
          id: "budget_owner",
        },
        {
          id: "status",
          label: "Subscription Status",
        },
        {
          id: "group",
        },
        {
          id: "enrollment_account",
        },
        {
          id: "subscription_owner",
        },
        {
          id: "subscription_name",
        },
        {
          id: "subscription_id",
        },
        {
          id: "managed_by",
        },
        {
          id: "technical_owner",
        },
        {
          id: "vams_id",
        },
        {
          id: "environment",
        },
      ],
    };
    return o[csp].map((v) => ({ ...v, table: "Table_2" }));
  }

  // only to be used with CostDaily data store
  public get azureFilters(): Filter[] {
    return [
      {
        displayName: "Select Date Range",
        id: "Date",
        selected: new Set<string>(),
        table: "Table_1",
        operators: [">=", "<="],
        values: [
          this.addMonths(new Date(), -4).toISOString().substring(0, 10),
          this.addMonths(new Date(), 0, 0).toISOString().substring(0, 10),
        ],
        endOfDateRange: "exclusive",
        type: FiltersType.date,
        query: "",
        datePickerRange: true,
      } as Filter,
      {
        displayName: "Enrollment Account",
        id: "enrollment_account",
        selected: new Set(),
        values: [],
        table: "Table_2",
        type: FiltersType.string,
        query: "",
      } as Filter,
      {
        displayName: "Account Owner",
        id: "AccountOwnerId",
        selected: new Set(),
        values: [],
        table: "Table_1",
        type: FiltersType.string,
        query: "",
      } as Filter,
      {
        displayName: "Resource Group",
        id: "ResourceGroup",
        selected: new Set(),
        values: [],
        table: "Table_1",
        type: FiltersType.string,
        query: "",
      } as Filter,
      {
        displayName: "Charge",
        id: "MeterName",
        selected: new Set(),
        values: [],
        table: "Table_1",
        type: FiltersType.string,
        query: "",
      } as Filter,
      {
        displayName: "Location",
        id: "ResourceLocation",
        selected: new Set(),
        values: [],
        table: "Table_1",
        type: FiltersType.string,
        query: "",
      } as Filter,
      {
        displayName: "Region",
        id: "MeterRegion",
        selected: new Set(),
        values: [],
        table: "Table_1",
        type: FiltersType.string,
        query: "",
      } as Filter,
      {
        displayName: "Sub-Category",
        id: "MeterSubCategory",
        selected: new Set(),
        values: [],
        table: "Table_1",
        type: FiltersType.string,
        query: "",
      } as Filter,
      {
        displayName: "Service Category",
        id: "MeterCategory",
        selected: new Set(),
        values: [],
        table: "Table_1",
        type: FiltersType.string,
        query: "",
      } as Filter,
      {
        displayName: "Service",
        id: "ConsumedService",
        selected: new Set(),
        values: [],
        table: "Table_1",
        type: FiltersType.string,
        query: "",
      } as Filter,
      {
        displayName: "Charge Frequency",
        id: "Frequency",
        selected: new Set(),
        values: [],
        table: "Table_1",
        type: FiltersType.string,
        query: "",
      } as Filter,
      {
        displayName: "Plan",
        id: "PlanName",
        selected: new Set(),
        values: [],
        table: "Table_1",
        type: FiltersType.string,
        query: "",
      } as Filter,
      {
        displayName: "Billing Account",
        id: "BillingAccountName",
        selected: new Set(),
        values: [],
        table: "Table_1",
        type: FiltersType.string,
        query: "",
      } as Filter,
      {
        displayName: "Charge Type",
        id: "ChargeType",
        selected: new Set(),
        values: [],
        table: "Table_1",
        type: FiltersType.string,
        query: "",
      } as Filter,
      {
        displayName: "Vendor",
        id: "PublisherName",
        selected: new Set(),
        values: [],
        table: "Table_1",
        type: FiltersType.string,
        query: "",
      } as Filter,
      {
        displayName: "Vendor Type",
        id: "PublisherType",
        selected: new Set(),
        values: [],
        table: "Table_1",
        type: FiltersType.string,
        query: "",
      } as Filter,
      {
        displayName: "Publisher",
        id: "PublisherType",
        selected: new Set(),
        values: [],
        table: "Table_1",
        type: FiltersType.string,
        query: "",
      } as Filter,
      {
        displayName: "Product",
        id: "ProductName",
        selected: new Set(),
        values: [],
        table: "Table_1",
        type: FiltersType.string,
        query: "",
      } as Filter,
      {
        displayName: "Local Market",
        id: "local_market",
        selected: new Set(),
        values: [],
        type: FiltersType.string,
        table: "Table_2",
        query: "",
      } as Filter,
      {
        displayName: "Subscription ID",
        id: "subscription_id",
        selected: new Set(),
        values: [],
        type: FiltersType.string,
        table: "Table_2",
        query: "",
      } as Filter,
      {
        displayName: "Subscription",
        id: "subscription_name",
        selected: new Set(),
        values: [],
        type: FiltersType.string,
        table: "Table_2",
        query: "",
      } as Filter,
      {
        displayName: "Business Unit",
        id: "bu",
        selected: new Set(),
        values: [],
        table: "Table_2",
        type: FiltersType.string,
        query: "",
      } as Filter,
      {
        displayName: "Programme",
        id: "programme",
        selected: new Set(),
        values: [],
        table: "Table_2",
        type: FiltersType.string,
        query: "",
      } as Filter,
      {
        displayName: "Cost Center",
        id: "CostCenter",
        selected: new Set(),
        values: [],
        table: "Table_1",
        type: FiltersType.string,
        query: "",
      } as Filter,
      {
        displayName: "Environment",
        id: "environment",
        selected: new Set(),
        values: [],
        type: FiltersType.string,
        table: "Table_2",
        query: "",
      } as Filter,
      {
        displayName: "Group",
        id: "group",
        selected: new Set(),
        values: [],
        type: FiltersType.string,
        table: "Table_2",
        query: "",
      } as Filter,
    ];
  }

  private injectFilterValues(
    mergeFilters: Filter[],
    {
      OrderBy,
      Projection,
      Limit,
      SortOrder,
      Distinct,
      ..._payload
    }: Partial<GroupByPayload> & { CloudProvider: CloudProvider; DataStore: string }
  ): Observable<Array<void>> {
    return forkJoin(
      mergeFilters
        .filter(({ datePickerRange, values }) => !datePickerRange && (values ?? []).length === 0)
        .map((f) => {
          const payload: GroupByPayload = {
            ...JSON.parse(JSON.stringify(_payload)),
            // inject date filters (if any)
            Filters: JSON.parse(JSON.stringify(_payload?.Filters ?? [])).concat(this.convertToBqFilters(mergeFilters)),
            Limit: f.getAll ? undefined : ROWS_PER_PAGE,
            Projection: [
              {
                Attribute: f.table ? `${f.table}.${f.id}` : f.id,
              },
            ],
            Distinct: true,
          };

          // inject date filters (if any)
          payload.Filters = ((payload.Filters as QueryFilterSimple[]) ?? []).concat(
            this.convertToBqFilters(mergeFilters)
          );

          // optimisations
          if (
            payload.SelfJoin === undefined &&
            (payload.SelfJoins?.length ?? 0) === 0 &&
            (!Array.isArray(payload.Filters) || payload.Filters.length === 0) &&
            (!Array.isArray(payload.GroupBy) || payload.GroupBy.length === 0) &&
            (!Array.isArray(payload.GroupByV2) || payload.GroupByV2.length === 0)
          ) {
            if (f.table === "Table_1") {
              // no need to join to another table
              delete payload.Join;
            } else if (f.table === "Table_2") {
              // if joining to another table but only getting the column from the other table,
              // then there is no need to query the first table at all
              payload.DataStore = payload.Join.DataStore;
              payload.Projection = payload.Projection.map(({ Attribute, ...rest }) => ({
                Attribute: Attribute.replace(/^Table_2/, "Table_1"),
                ...rest,
              }));
              delete payload.Join;
            }
          }

          if (payload?.Join?.Type !== "LEFT" && payload?.Join?.DataStore === "CloudResourcesMetadata") {
            payload.Join.Type = "LEFT";
          }

          const ops = [
            map((response: { Results: { Group: string[]; Value: string[] }[]; Metadata: QueryMetadata }) => {
              const newValues = (response?.Results ?? [])
                .filter(({ Value }) => Array.isArray(Value))
                .map(({ Value }) => Value[0]);
              f.values = [...new Set((f.values ?? []).concat(newValues))].sort();
              this.log.info({ msg: "injected filter value", filter: f });
            }),
            catchError((e) => {
              this.log.error(e);
              return of(undefined);
            }),
          ];

          /* if (
            (payload?.DataStore === "CloudResourcesMetadata" ||
              payload?.Join?.DataStore === "CloudResourcesMetadata") &&
            payload?.Projection?.length === 1 &&
            !(payload?.Filters ?? []).some(
              (f) => ((f as QueryFilterSimple)?.Attribute ?? "").split(".").slice(-1)[0] === "status"
            ) &&
            !(payload?.Projection ?? []).some((f) => (f?.Attribute ?? "").split(".").slice(-1)[0] === "status")
          ) {
            if (payload.CloudProvider === "aws") {
              payload.Filters = (payload?.Filters ?? []).concat([
                {
                  Type: "STRING",
                  Attribute: "status",
                  Operator: "=",
                  Table: payload?.DataStore === "CloudResourcesMetadata" ? "Table_1" : "Table_2",
                  Value: "ACTIVE",
                } as QueryFilterSimple,
              ]);
            } else if (payload.CloudProvider === "gcp") {
              payload.Filters = (payload?.Filters ?? []).concat([
                {
                  Type: "STRING",
                  Attribute: "status",
                  Operator: "=",
                  Table: payload?.DataStore === "CloudResourcesMetadata" ? "Table_1" : "Table_2",
                  Value: "ACTIVE",
                } as QueryFilterSimple,
              ]);
            } else if (payload.CloudProvider === "azure") {
              payload.Filters = (payload?.Filters ?? []).concat([
                {
                  Type: "STRING",
                  Attribute: "status",
                  Operator: "!=",
                  Table: payload?.DataStore === "CloudResourcesMetadata" ? "Table_1" : "Table_2",
                  Value: "Deleted",
                } as QueryFilterSimple,
              ]);
            }
          } */

          if (f.getAll) {
            // @ts-ignore
            return this.groupByGetAll<string>(payload).pipe(...ops);
          } else {
            // @ts-ignore
            return this.groupBy<string>(payload).pipe(...ops);
          }
        })
    ).pipe(
      catchError((e) => {
        this.log.error(e);
        return of([]);
      })
    );
  }

  public decodeGCPEmail(email: string): string {
    if (!email) {
      return "--";
    }
    let letters = "";
    let atFound = false;
    for (let i = email.length - 1; i >= 0; i--) {
      if (!atFound && email[i] === "-") {
        letters += "@";
        atFound = true;
      } else if (email[i] === "_") {
        letters += ".";
      } else {
        letters += email[i];
      }
    }
    return letters.split("").reverse().join("");
  }

  private applyShareLinkFilters(
    share: {
      Filters: { all: Array<{ all?: Array<{ eq: [string, string] }>; any?: Array<{ eq: [string, string] }> }> };
    },
    mergeFilters: Filter[]
  ): void {
    for (const f of share?.Filters?.all ?? []) {
      try {
        const filterId = (f?.any ?? f?.all).find(({ eq: [key, _val] }) => !!key).eq[0];
        const filter: Filter = mergeFilters.find((f) => f.id === filterId);
        if (filter) {
          this.log.info({ msg: "matched filter", filter, filterId });
        }
        if (filter?.datePickerRange && Array.isArray(f.all)) {
          filter.values = f.all.map((v) => v.eq[1]);
          this.log.info({ msg: "date filter", filter, filterId });
        } else if (filter?.selected !== undefined) {
          filter.selected.clear();
          let sortRequired = false;
          for (const v of f.any ?? []) {
            filter.selected.add(v.eq[1]);
            if (!filter.values.includes(v.eq[1])) {
              filter.values.push(v.eq[1]);
              sortRequired = true;
            }
          }
          if (sortRequired) {
            filter.values.sort();
          }
          this.log.info({ msg: "value filter", filter, filterId, sortRequired });
        }
      } catch (e) {
        this.log.error(e);
      }
    }
  }

  private valuesEqual<T extends number | boolean | string, K extends number | boolean | string>(
    value1: T | T[],
    value2: K | K[]
  ): boolean {
    if (!Array.isArray(value1) || !Array.isArray(value2)) {
      return value1 === (value2 as unknown as T);
    }
    if (value1.length !== value2.length) {
      return false;
    }
    const s2 = new Set(value2);
    for (const v1 of value1) {
      if (!s2.has(v1 as unknown as K)) {
        return false;
      }
    }
    const s1 = new Set(value1);
    for (const v2 of value2) {
      if (!s1.has(v2 as unknown as T)) {
        return false;
      }
    }
    return true;
  }

  private filtersEqual(newFilter: QueryFilterSimple, existingFilter: QueryFilterSimple): boolean {
    return (
      existingFilter.Attribute === newFilter.Attribute &&
      existingFilter.Table === newFilter.Table &&
      existingFilter.Operator === newFilter.Operator &&
      existingFilter.Type === newFilter.Type &&
      this.valuesEqual(existingFilter.Value, newFilter.Value)
    );
  }

  private deduplicateFilters(filters: (QueryFilterSimple | FilterableL1)[]): (QueryFilterSimple | FilterableL1)[] {
    if ((filters as FilterableL1).Filters !== undefined) {
      return filters;
    }
    const newFilters: QueryFilterSimple[] = [];
    for (const newFilter of filters) {
      if (!newFilters.some((existingFilter) => this.filtersEqual(newFilter as QueryFilterSimple, existingFilter))) {
        newFilters.push(newFilter as QueryFilterSimple);
      }
    }
    return newFilters;
  }

  /**
   * Cascading filters
   *
   * Each time there is a change to filters, in addition to applying them to all open sections, we reload the filters by applying old filters.
   */
  public handleFiltersChange(
    filters: Filter[],
    { Filters, ...requestBody }: GroupByPayload
  ): Observable<{
    vCloudFilters: QueryFilterSimple[];
    filters: Filter[];
  }> {
    for (const f of filters) {
      if (!f.datePickerRange && f?.selected?.size === 0) {
        f.values = [];
      }
    }
    return this.getFilters(
      filters,
      {
        ...requestBody,
        Filters: ((Filters ?? []) as QueryFilterSimple[]).concat(this.convertToBqFilters(filters)),
      },
      null
    ).pipe(
      map(() => {
        return {
          filters,
          vCloudFilters: this.convertToBqFilters(filters),
        };
      })
    );
  }

  public getFilters(
    mergeFilters: Filter[],
    payload: Partial<GroupByPayload> & { CloudProvider: CloudProvider; DataStore: string },
    shareId: string
  ): Observable<QueryFilterSimple[]> {
    // page components need to wait for this request
    // and apply the filters to the initial requests used to populate the page
    return (
      shareId
        ? this.pcsApi
            .get<{ Filters: { all: Array<{ any: Array<{ eq: [string, string] }> }> } }>(
              `tenancies-v1/share-codes/${shareId}`
            )
            .pipe(
              catchError((e) => {
                this.log.error(e);
                return of({ Filters: { all: [] } });
              })
            )
        : of({ Filters: { all: [] } })
    ).pipe(
      mergeMap((share) => {
        if ((share?.Filters?.all?.length ?? 0) === 0) {
          this.injectFilterValues(mergeFilters, payload).subscribe((result) => {
            this.log.info({ msg: "injected filter values", result, share, mergeFilters, payload, shareId });
          });
          return of(this.convertToBqFilters(mergeFilters));
        } else {
          this.applyShareLinkFilters(share, mergeFilters);
          this.log.info({ msg: "applied share link to filters", share, mergeFilters, payload, shareId });
          return this.handleFiltersChange(mergeFilters, payload).pipe(
            map(({ vCloudFilters }) => {
              return vCloudFilters;
            })
          );
        }
      })
    );
  }

  public convertToBqFilters(filters: Filter[]): QueryFilterSimple[] {
    return filters
      .filter(({ datePickerRange, selected, values }) => {
        const nSelected = selected?.size ?? 0;
        const nValues = values?.length ?? 0;
        // nValues is 0 when recalling filters from share ID
        return !datePickerRange && nSelected > 0 && (nValues === 0 || nSelected !== nValues);
      })
      .map(({ id, selected, table, type }: Filter): QueryFilterSimple => {
        const values: Array<FilterValue> = [...selected];
        if (type === FiltersType.float64) {
          for (let i = 0; i < values.length; i++) {
            if (typeof values[i] === "string") {
              try {
                values[i] = parseFloat(values[i] as string);
              } catch (e) {
                this.log.error(e);
              }
            }
          }
        } else if (type === FiltersType.int64) {
          for (let i = 0; i < values.length; i++) {
            if (typeof values[i] === "string") {
              try {
                values[i] = parseInt(values[i] as string);
              } catch (e) {
                this.log.error(e);
              }
            }
          }
        } else if (type === FiltersType.bool) {
          for (let i = 0; i < values.length; i++) {
            if (typeof values[i] === "string") {
              try {
                values[i] = (values[i] as string).toLowerCase().trim() === "true";
              } catch (e) {
                this.log.error(e);
              }
            }
          }
        }
        return {
          Table: table,
          Type: type,
          Attribute: id,
          Operator: "IN",
          Value: values,
        };
      })
      .concat(
        filters
          .filter(({ datePickerRange }) => datePickerRange)
          .map(({ id, operators, monthYearMode, table, type, values: [startDate, endDate] }) => {
            let startOperator = ">=";
            let endOperator = "<=";
            if (operators) {
              startOperator = operators[0] ?? ">=";
              endOperator = operators[1] ?? "<=";
            }
            if (startDate === endDate && (monthYearMode || (startOperator === ">=" && endOperator === "<="))) {
              return [
                {
                  Table: table,
                  Type: type,
                  Attribute: id,
                  Operator: "=",
                  Value: startDate,
                } as QueryFilterSimple,
              ];
            }
            return [
              {
                Table: table,
                Type: type,
                Attribute: id,
                Operator: startOperator,
                Value: startDate,
              } as QueryFilterSimple,
              {
                Table: table,
                Type: type,
                Attribute: id,
                Operator: endOperator,
                Value: endDate,
              } as QueryFilterSimple,
            ];
          })
          .flat()
      );
  }

  public getHeaders(): HttpHeaders {
    const headers = {
      Authorization: `Bearer ${this.auth.getIdToken()}`,
      Accept: "application/json, */*",
    };
    const tenant = this.auth.tenant;
    if (tenant && tenant !== "undefined" && tenant !== "null") {
      headers["x-vf-api-tenant"] = tenant;
    }
    return new HttpHeaders(headers);
  }

  private request<T>(
    method: string,
    url: string,
    options: Partial<{
      headers: HttpHeaders;
      body: any;
      params: HttpParams;
    }> = {}
  ): Observable<T> {
    let headers = new HttpHeaders();

    try {
      headers = this.getHeaders();
    } catch (e) {
      if (e.message === "Session Expired") {
        this.auth.autoLogout();
      }
      return throwError(e);
    }

    if (options.headers) {
      for (const k of options.headers.keys()) {
        headers.set(k, options.headers.get(k));
      }
    }

    const base = 1;
    const cap = 6;
    const sec = SECOND;
    let tries = 0;
    const maxTries = environment.production ? 4 : 0;

    const request = this.http.request<T>(method, `${this.baseUrl}/${url}`, {
      body: options.body,
      headers,
      observe: "body",
      responseType: "json",
      params: options.params,
    });

    const observable = request.pipe(
      catchError((e) => {
        if (tries >= maxTries) {
          // centralised logging of all API errors
          this.log.error(`Error while requesting ${method} ${url} (tried ${tries}/${maxTries} times)`, options, e);
          return throwError(e);
        } else {
          return of(null).pipe(
            tap(() => tries++),
            delay(Math.ceil(sec * (Math.random() * Math.min(cap, base * 2 ** tries)))),
            mergeMap(() => observable)
          );
        }
      })
    );

    return observable;
  }

  public get<T>(url: string): Observable<T> {
    return this.request<T>("get", url);
  }

  public post<T, K>(url: string, body: K): Observable<T> {
    return this.request<T>("post", url, { body });
  }

  private async groupByGetAllHelper<T extends string | number>(
    { Limit, Offset, ...body }: GroupByPayload,
    pageSize: number,
    exportDuration: number = MAX_EXPORT_DURATION,
    parallelism: number = MAX_EXPORT_PARALLELISM
  ): Promise<{ Results: { Group: string[]; Value: T[] }[] }> {
    let offset = 0;
    let allRows = [];
    const startTime = new Date().getTime();
    while (true) {
      this.log.info("processing next batch for export...");
      const tasks: Array<Promise<{ Results: { Group: string[]; Value: T[] }[]; Metadata?: QueryMetadata }>> = [];
      for (let i = 0; i < parallelism; i++) {
        this.log.info({ task: i, offset, pageSize });
        tasks.push(this.groupBy<T>({ ...body, Limit: pageSize, Offset: offset }).toPromise());
        offset += pageSize;
      }
      let lastResponse: { Results: { Group: string[]; Value: T[] }[]; Metadata?: QueryMetadata };
      for (const t of tasks) {
        let response: { Results: { Group: string[]; Value: T[] }[]; Metadata?: QueryMetadata };
        try {
          response = await t;
        } catch (e) {
          this.log.error(e);
          await Swal.fire({
            title: "Error",
            html: `An error has been encountered while exporting data. Retrieved only the first ${allRows.length} rows.`,
            icon: "error",
          });
          return { ...lastResponse, Results: allRows };
        }
        allRows = allRows.concat(response?.Results ?? []);
        lastResponse = response;
        this.log.info({ lastResponse, allRows, response });
      }
      const now = new Date().getTime();
      const timeExceeded = now - startTime >= exportDuration;
      const noMoreData = (lastResponse?.Results?.length ?? 0) < pageSize;
      this.log.info({ now, startTime, timeExceeded, noMoreData });
      if (noMoreData || timeExceeded) {
        if (timeExceeded) {
          await Swal.fire({
            title: "Warning",
            html: `You have reached the limit of ${Math.ceil(
              exportDuration / SECOND
            )} seconds. Retrieved only the first ${allRows.length} rows.`,
            icon: "warning",
          });
        }
        return { ...lastResponse, Results: allRows };
      }
    }
  }

  /**
   * WARNING:
   *
   * this should only be used in exceptional circumstances,
   * it will paginate through the entire content of the table and return ALL rows.
   *
   * Good page value sizes are between 2000 and 5000.
   */
  public groupByGetAll<T extends string | number>(
    body: GroupByPayload,
    pageSize = ROWS_PER_PAGE,
    exportDuration = MAX_EXPORT_DURATION,
    parallelism = MAX_EXPORT_PARALLELISM
  ): Observable<{ Results: { Group: string[]; Value: T[] }[] }> {
    return from(this.groupByGetAllHelper<T>(body, pageSize, exportDuration, parallelism));
  }

  private simplifyFilter(filter: QueryFilterSimple): QueryFilterSimple | FilterableL1 {
    try {
      if (Array.isArray(filter?.Value)) {
        if ((filter?.Value?.length ?? 0) === 1) {
          if (filter?.Operator === "IN") {
            const f = JSON.parse(JSON.stringify(filter));
            const firstVal = f.Value[0];
            if (firstVal === true || firstVal === false || firstVal === null) {
              f.Operator = "IS";
            } else {
              f.Operator = "=";
            }
            f.Value = firstVal;
            return f;
          } else if (filter?.Operator === "NOT IN") {
            const f = JSON.parse(JSON.stringify(filter));
            const firstVal = f.Value[0];
            if (firstVal === true || firstVal === false || firstVal === null) {
              f.Operator = "IS NOT";
            } else {
              f.Operator = "!=";
            }
            f.Value = firstVal;
            return f;
          }
        } else if ((filter?.Value?.length ?? 0) > 1) {
          const valuesThatNeedIs = (filter?.Value ?? []).filter((v) => v === null || v === true || v === false);
          if (valuesThatNeedIs.length > 0) {
            return {
              FiltersJoinWith: "OR",
              Filters: valuesThatNeedIs
                .map((v) => this.simplifyFilter({ ...filter, Value: [v] }))
                .concat([
                  this.simplifyFilter({
                    ...filter,
                    Value: filter.Value.filter((v) => v !== null && v !== true && v !== false),
                  }),
                ]),
            } as FilterableL1;
          }
        }
      }
    } catch (e) {
      this.log.error(e);
    }
    return filter;
  }

  public hashCodeV2(str: string, seed = 0): string {
    let h1 = 0xdeadbeef ^ seed,
      h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return (4294967296 * (2097151 & h2) + (h1 >>> 0)).toString();
  }

  private getSlowness(t: string, v: any): number {
    if (typeof v === "string") {
      if (t === FiltersType.date || t === FiltersType.timestamp) {
        return 2;
      } else {
        return v.length;
      }
    }
    if (Array.isArray(v)) {
      return v.map((k) => this.getSlowness(t, k)).reduce((a, b) => a + b, 0);
    }
    return 1;
  }

  private orderFilters(filters: QueryFilterSimple[]): QueryFilterSimple[] {
    const scores: { [attr: string]: number } = {};
    for (const f of filters) {
      scores[f.Table ? `${f.Table}.${f.Attribute}` : f.Attribute] =
        (OPERATOR_SCORES[f.Operator] ?? 1) * this.getSlowness(f.Type, f.Value);
    }
    this.log.info({ msg: "filter scores", scores });
    return [...filters].sort((a, b) => {
      const s1 = scores[a.Table ? `${a.Table}.${a.Attribute}` : a.Attribute];
      const s2 = scores[b.Table ? `${b.Table}.${b.Attribute}` : b.Attribute];
      if (s1 > s2) {
        return 1;
      } else if (s1 === s2) {
        return 0;
      } else {
        return -1;
      }
    });
  }

  public getTotalQueryCost(type: "all" | "bq" | "llm", reportPage: string): Decimal {
    let totalCost = new Decimal(0);
    const queriesStr = sessionStorage.getItem("expensiveQueries");
    if (queriesStr) {
      const queries: { [path: string]: { [jobId: string]: string } } = JSON.parse(queriesStr);
      if (queries !== undefined && queries !== null) {
        for (const path in queries) {
          if (reportPage && path !== reportPage) {
            continue;
          }
          const page = queries[path];
          if (page !== undefined && page !== null) {
            for (const queryId in page) {
              if (type === "all" || queryId.startsWith(type)) {
                totalCost = totalCost.add(page[queryId] ?? "0");
              }
            }
          }
        }
      }
    }
    return totalCost;
  }

  public getTotalQueryCount(): number {
    let totalQueries = 0;
    const queriesStr = sessionStorage.getItem("expensiveQueries");
    if (queriesStr) {
      const queries = JSON.parse(queriesStr);
      if (queries !== undefined && queries !== null) {
        for (const page in queries) {
          for (const _ in queries[page]) {
            totalQueries++;
          }
        }
      }
    }
    return totalQueries;
  }

  public getThisReportQueries(): number {
    let totalQueries = 0;
    const queriesStr = sessionStorage.getItem("expensiveQueries");
    if (queriesStr) {
      const queries = JSON.parse(queriesStr);
      if (queries !== undefined && queries !== null && location.pathname in queries) {
        for (const _ in queries[location.pathname]) {
          totalQueries++;
        }
      }
    }
    return totalQueries;
  }

  public handleQueryCost({
    Metadata: { job_id: jobId, cost: c },
  }: {
    Metadata: { job_id?: string; cost?: string };
  }): void {
    const cost = new Decimal((c ?? `0.0 ${QUERY_COST_CURRENCY}`).split(" ")[0] ?? "0.0");
    const queryLog: { [path: string]: { [queryId: string]: string } } =
      JSON.parse(sessionStorage.getItem("expensiveQueries") ?? "{}") ?? {};
    if (location.pathname in queryLog) {
      queryLog[location.pathname][jobId ?? "???"] = cost.toString();
    } else {
      queryLog[location.pathname] = { [jobId ?? "???"]: cost.toString() };
    }
    sessionStorage.setItem("expensiveQueries", JSON.stringify(queryLog));
    if (cost.gte(QUERY_COST_THRESHOLD)) {
      this.alerts.addAlert({
        text: `<p><strong>Expensive Query!!!</strong><br><br>Consumed ${cost
          .toDecimalPlaces(2)
          .toNumber()} ${QUERY_COST_CURRENCY}. Job ID: ${
          jobId ?? "???"
        }. You must optimise your query or reach out to someone working on the backend.</p>`,
        type: "danger",
        dismissOnTimeout: 20000,
        dismissible: true,
      });
    }
  }

  public groupByAsync(body: GroupByPayload): Observable<void> {
    const requestBody = JSON.parse(this.JSONStringifyStable(body));

    if (requestBody.Offset) {
      delete requestBody.Offset;
    }

    if (requestBody.Limit) {
      delete requestBody.Limit;
    }

    const allFilters = requestBody?.Filters ?? [];

    const simplifiedFilters = allFilters
      .filter((f) => (f as FilterableL2).Filters === undefined)
      .map((f) => this.simplifyFilter(f));
    const deduplicatedSimplifedFilters = this.deduplicateFilters(simplifiedFilters);

    const orderedSimpleFilters = deduplicatedSimplifedFilters
      .filter(
        (f) =>
          (f as QueryFilterSimple).Type === FiltersType.date || (f as QueryFilterSimple).Type === FiltersType.timestamp
      )
      .concat(
        this.orderFilters(
          deduplicatedSimplifedFilters.filter(
            (f) =>
              (f as QueryFilterSimple).Type !== FiltersType.date &&
              (f as QueryFilterSimple).Type !== FiltersType.timestamp
          ) as QueryFilterSimple[]
        )
      );

    const complexFilters = allFilters.filter((f) => (f as FilterableL2).Filters !== undefined);

    requestBody.Filters = orderedSimpleFilters.concat(complexFilters);

    const url = location.href.replace(location.origin, "");

    const segments: string[] = url
      .split("/")
      .filter(Boolean)
      .filter((s) => s !== "v2" && s !== "vcloudsmart");

    return this.post("queries/group-by", {
      ...requestBody,
      Async: true,
      Metadata: Object.fromEntries(
        Object.entries({
          Url: url,
          CloudProvider: segments[0],
          Section: segments[1],
          Page: segments[2],
        }).filter((e) => !!e[1])
      ),
    });
  }

  public groupBy<T extends number | string>(
    body: GroupByPayload
  ): Observable<{ Results: { Group: string[]; Value: T[] }[]; Metadata?: QueryMetadata }> {
    let o: Observable<{ Results: { Group: string[]; Value: T[] }[]; Metadata?: QueryMetadata }>;

    const requestBody: GroupByPayload = JSON.parse(this.JSONStringifyStable(body));

    if (requestBody.OrderBy?.length === 0) {
      delete requestBody.OrderBy;
    }

    // no need to run the request
    if (requestBody.Limit === 0) {
      return of({ Results: [] });
    }

    // default value
    if (requestBody.SelectGroups === true) {
      delete requestBody.SelectGroups;
    }

    // default value
    if (requestBody.SortOrder === "DESC") {
      delete requestBody.SortOrder;
    }

    // default value
    if (requestBody.Offset === 0) {
      delete requestBody.Offset;
    }

    const allFilters = requestBody?.Filters ?? [];

    const simplifiedFilters = allFilters
      .filter((f) => (f as FilterableL2).Filters === undefined)
      .map((f) => this.simplifyFilter(f as QueryFilterSimple));
    const deduplicatedSimplifedFilters = this.deduplicateFilters(simplifiedFilters);

    const orderedSimpleFilters = deduplicatedSimplifedFilters
      .filter(
        (f) =>
          (f as QueryFilterSimple).Type === FiltersType.date || (f as QueryFilterSimple).Type === FiltersType.timestamp
      )
      .concat(
        this.orderFilters(
          deduplicatedSimplifedFilters.filter(
            (f) =>
              (f as QueryFilterSimple).Type !== FiltersType.date &&
              (f as QueryFilterSimple).Type !== FiltersType.timestamp
          ) as QueryFilterSimple[]
        )
      );

    const complexFilters = allFilters.filter((f) => (f as FilterableL2).Filters !== undefined);

    requestBody.Filters = orderedSimpleFilters.concat(complexFilters) as QueryFilterSimple[];

    const cacheKey = `response-${this.hashCodeV2(this.JSONStringifyStable(requestBody))}`;
    const maybeCache = sessionStorage.getItem(cacheKey);

    if (maybeCache) {
      // clone
      const data: { Group: string[]; Value: T[] }[] = JSON.parse(maybeCache);
      this.log.info({ msg: "cache hit", cacheKey, data, requestBody });
      return of({ Results: data }).pipe(tap((response) => this.captureResponse(response, body)));
    } else {
      this.log.debug({ msg: "cache miss", cacheKey, requestBody });
    }

    o = this.post("queries/group-by", requestBody);

    return o.pipe(
      tap(({ Results }) => {
        const resultsSafe = Results ?? [];

        for (const r of resultsSafe) {
          for (let i = 0; i < (r?.Value?.length ?? 0); i++) {
            if (r?.Value[i] === -0) {
              // @ts-ignore
              r.Value[i] = 0;
            }
          }
          for (let i = 0; i < (r?.Group?.length ?? 0); i++) {
            // @ts-ignore
            if (r?.Group[i] === -0) {
              // @ts-ignore
              r.Group[i] = 0;
            }
          }
        }

        // avoid messing with pagination
        if ((requestBody?.Offset ?? 0) !== 0) {
          return;
        }

        const cols = requestBody?.Projection?.length ?? 1;
        const rows = resultsSafe?.length ?? 0;

        if (rows * cols >= ROWS_PER_PAGE || rows * cols === 0) {
          return;
        }

        const storageStats = this.pcsApi.howMuchStorageLeft(sessionStorage);
        const resultsStringified = JSON.stringify(resultsSafe);
        this.log.info({ storageStats, dataSize: resultsStringified.length * 2 });

        if ((resultsStringified.length * 2) / storageStats.limit >= 0.01) {
          return;
        }

        if (resultsStringified.length * 2 >= storageStats.bytesLeft || storageStats.percent >= 0.85) {
          this.log.warning("revoking response cache");
          for (const key of Object.keys(sessionStorage).filter((k) => k.startsWith("response-"))) {
            sessionStorage.removeItem(key);
          }
        }

        this.log.info({
          msg: "caching",
          nCells: rows * cols,
          cacheKey,
          data: resultsSafe,
          requestBody,
          dataBytes: resultsStringified.length * 2,
          dataChars: resultsStringified.length,
        });
        sessionStorage.setItem(cacheKey, resultsStringified);
      }),
      tap(({ Metadata }) => {
        if (!environment.production) {
          setTimeout(() => {
            this.handleQueryCost({ Metadata: { job_id: `bq-${Metadata.job_id}`, cost: Metadata.cost } });
          }, 2000);
        }
      }),
      tap((response) => this.captureResponse(response, body))
    );
  }

  private isFiltersRequest(body: GroupByPayload): boolean {
    return (
      (body?.GroupBy?.length ?? 0) === 0 &&
      (body?.GroupByV2?.length ?? 0) === 0 &&
      body?.Distinct === true &&
      body?.Projection?.length === 1 &&
      ((body?.Limit ?? ROWS_PER_PAGE) === ROWS_PER_PAGE ||
        (body?.Limit ?? MAX_API_RESPONSE_LIMIT) === MAX_API_RESPONSE_LIMIT) &&
      !body?.Projection[0].Operation
    );
  }

  public set capturedResponses(value: { [pathname: string]: Array<{ body: GroupByPayload; results: Array<any> }> }) {
    sessionStorage.setItem("capturedResponsesVcs", JSON.stringify(value));
  }

  public get capturedResponses(): { [pathname: string]: Array<{ body: GroupByPayload; results: Array<any> }> } {
    const maybe = sessionStorage.getItem("capturedResponsesVcs");
    if (maybe) {
      const value = JSON.parse(maybe);
      if (value) {
        return value;
      }
    }
    return { [location.pathname]: [] };
  }

  private captureResponse(response: GroupByApiResult, body: GroupByPayload): void {
    const key = location.pathname;
    if (key.includes("/vcloudsmart/assistant")) {
      return;
    }
    const cellCount = (response?.Results?.length ?? 0) * (body?.Projection?.length ?? 0);
    const stringifiedBody = this.JSONStringifyStable(body);
    if (cellCount > 0 && !this.isFiltersRequest(body)) {
      const cr = this.capturedResponses;
      cr[key] = (cr[key] ?? []).filter((f) => this.JSONStringifyStable(f.body) !== stringifiedBody).slice(-20);
      cr[key].push({ body, results: response.Results.slice(0, 10) });
      this.capturedResponses = cr;
    }
  }

  public sortFilters<T extends FilterableL1 | FilterableL2>(payload: T): T {
    const { Filters, ...rest } = payload;
    if ((Filters?.length ?? 0) > 0) {
      for (let i = 0; i < (payload?.Filters?.length ?? 0); i++) {
        if (((Filters[i] as FilterableL2)?.Filters?.length ?? 0) > 0) {
          Filters[i] = this.sortFilters(Filters[i] as FilterableL1) as FilterableL2;
        }
      }
      // @ts-ignore
      return {
        ...rest,
        Filters: (Filters ?? []).sort((a, b): number => {
          const isANested = ((a as FilterableL2)?.Filters ?? [])?.length > 0;
          const isBNested = ((b as FilterableL2)?.Filters ?? [])?.length > 0;
          if (isANested && isBNested) {
            return ((a as FilterableL2)?.Filters ?? [])?.length >= ((b as FilterableL2)?.Filters ?? [])?.length
              ? -1
              : 1;
          } else if (isBNested) {
            return -1;
          } else if (isANested) {
            return 1;
          } else {
            return ((a as QueryFilterSimple)?.Attribute ?? "").localeCompare((b as QueryFilterSimple)?.Attribute ?? "");
          }
        }) as (FilterableL2 | QueryFilterSimple)[],
      };
    }
    return payload;
  }

  public JSONStringifyStable(obj: GroupByPayload): string {
    const allKeys = new Set<string>();
    obj = this.sortFilters(obj);

    JSON.stringify(obj, (key, value) => (allKeys.add(key), value));
    return JSON.stringify(obj, Array.from(allKeys).sort());
  }

  public addDays(date: number | string | Date, days: number): Date {
    const result = new Date(date);
    if (days !== 0) {
      result.setDate(result.getDate() + days);
    }
    return result;
  }

  // DO NOT USE
  public updateVCloudFilters(
    currentFilters: QueryFilterSimple[],
    newFilters: QueryFilterSimple[],
    startDate?: Date,
    endDate?: Date
  ): QueryFilterSimple[] {
    const Filters: QueryFilterSimple[] = [];
    if (currentFilters[0]) Filters.push(currentFilters[0]);
    if (currentFilters[1]) Filters.push(currentFilters[1]);
    if (newFilters.length > 0) Filters.push(...newFilters);
    if (startDate) {
      if (Filters[0].Type === FiltersType.date) {
        Filters[0].Value = startDate.toISOString().split("T")[0];
      } else {
        Filters[0].Value = startDate.toISOString();
      }
    }
    if (endDate) {
      if (Filters[1].Type === FiltersType.date) {
        Filters[1].Value = endDate.toISOString().split("T")[0];
      } else {
        Filters[1].Value = endDate.toISOString();
      }
    }
    return Filters;
  }

  // DO NOT USE, use https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
  public months: string[] = this.getMonths();

  private getMonths(): Array<string> {
    const year = new Date().getFullYear();
    const locales = navigator.language || "en-GB";
    const options = { month: "short" as "short" };
    return Array(12)
      .fill(null)
      .map((_, i) =>
        new Date(`${year}-${(i + 1).toString().padStart(2, "0")}-01`).toLocaleDateString(locales, options)
      );
  }

  public addMonths(_date: number | string | Date, months: number, dayNumber: number = 1): Date {
    // https://stackoverflow.com/questions/2706125/javascript-function-to-add-x-months-to-a-date#2706169
    let date = new Date(_date);
    let m,
      d = (date = new Date(+date)).getDate();
    date.setMonth(date.getMonth() + months, 1);
    m = date.getMonth();
    date.setDate(d);
    if (date.getMonth() !== m) {
      date.setDate(0);
    }
    date.setDate(dayNumber);
    return date;
  }

  public submitLLMRating(messageId: string, rating: number, feedback?: string): Observable<void> {
    const body = Object.fromEntries(
      Object.entries({ MessageId: messageId, Rating: rating, Feedback: feedback }).filter((e) => !!e[1])
    ) as { MessageId: string; Rating: number; Feedback?: string };
    return this.post<void, { MessageId: string; Rating: number; Feedback?: string }>("queries/llm/rating", body);
  }
}
