import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "trunc",
  standalone: true,
})
export class TruncPipe implements PipeTransform {
  transform(value: string, limit: number = 2 ** 32, marker = "..."): string {
    if (!value) {
      return "";
    }
    value = value.trimEnd();
    if (value.length > limit) {
      return value.substring(0, limit) + marker;
    } else {
      return value;
    }
  }
}
