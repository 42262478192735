import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";
import { Creativity, LLModel, LLMResponse, ModelConfig, QueryDomain } from "../../models/llm";
import { LoggerService } from "../../services/logger.service";
import { CommonModule } from "@angular/common";
import { LLMService } from "../../services/llm.service";
import { CloudProvider } from "../../models/vcloud-api";
import { convertMarkdownToHTML } from "../../constant/definitions";
import { Observable, of, Subscription } from "rxjs";
import {
  CLOUD_PROVIDERS_ALL,
  CLOUD_PROVIDERS_ENABLED,
  DOMAINS,
  MODEL_CHEAP_V2,
  MODEL_CONFIG,
} from "../../constant/llm";
import { VFIconComponent } from "../vficon/vficon.component";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import {
  DATA_INSTRUCTIONS_RECOMMEND,
  DATA_INSTRUCTIONS_SUMMARISE,
  DEFAULT_MODEL_CONFIG,
  DEFAULT_TITLE_ANALYSIS,
  DEFAULT_TITLE_RECOMMENDATIONS,
} from "../../constant/ai-analysis";
import { AiMode, VCloudAiSummaryData } from "../../models/ai-analysis";
import { Clipboard } from "@angular/cdk/clipboard";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { catchError, map, mergeMap } from "rxjs/operators";
import { DASH_REGEX } from "../../constant/async-export";
import { LookerStudioService } from "../../services/looker-studio.service";

const LOADING_PHRASES: string[] = [
  "Analysing data...",
  "Considering relevant facts...",
  "Detecting trends...",
  "Crafting a response...",
];

@Component({
  standalone: true,
  selector: "app-ai-summary",
  imports: [CommonModule, VFIconComponent, TooltipModule, CollapseModule],
  templateUrl: "./ai-summary.component.html",
  styleUrls: ["./ai-summary.component.scss"],
})
export class AiSummaryComponent implements OnChanges, OnDestroy {
  /**
   * To use this component you will normally provide the [data] input and the rest should just work.
   *
   * There is no fixed format of data but an object with keys that make sense is best:
   *
   * e.g.:
   *
   * {
   *   lastMonthCost: '$123.123K'
   *   currentMonthCost: '...',
   *   avgLastThreeMonths: '...',
   *   avgLastYear: '...'
   * }
   */

  // need to set in most cases
  @Input() data: VCloudAiSummaryData = "";

  @Input() initialMode: AiMode = "analyse";
  @Input() isExpandable: boolean = true;

  // no need to set if you set data
  @Input() userInstructionsAnalyse: string = "";
  @Input() userInstructionsRecommend: string = "";

  // can usually be inferred
  @Input() cloudProviders: CloudProvider[] = undefined;
  @Input() domains: QueryDomain[] = undefined;

  // optional, useful if you want to summarise non-financial data, the model is heavily skewed towards financial summaries so this might be used to reiterate that these are GiB units
  @Input() dataSummary: string = "";

  @Input() model: LLModel = MODEL_CHEAP_V2;
  @Input() modelConfig: ModelConfig = DEFAULT_MODEL_CONFIG;

  // no need to change in most cases
  @Input() title = DEFAULT_TITLE_ANALYSIS;

  public aiSummary: string;
  public mode: AiMode = "analyse";

  private subscriptions: Subscription[] = [];
  public creativityModified: boolean = false;

  public expanded: boolean = true;
  public creativity: Creativity = "LOW";
  private timeouts: number[] = [];

  public get loading(): boolean {
    return this.aiSummary === undefined;
  }

  constructor(
    private log: LoggerService,
    private llm: LLMService,
    private clipboard: Clipboard,
    private looker: LookerStudioService
  ) {}

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
    this.timeouts.forEach((s) => clearTimeout(s));
    this.timeouts = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.log.info(changes);
    this.ngOnDestroy();
    if (this.cloudProviders === undefined) {
      this.cloudProviders = this.inferCloudProviders();
    }
    if (this.domains === undefined) {
      this.domains = this.inferDomains();
    }
    if (this.data && !this.userInstructionsAnalyse) {
      this.userInstructionsAnalyse = DATA_INSTRUCTIONS_SUMMARISE;
    }
    if (this.data && !this.userInstructionsRecommend) {
      this.userInstructionsRecommend = DATA_INSTRUCTIONS_RECOMMEND;
    }
    if (typeof this.data === "object" && !Array.isArray(this.data)) {
      this.data = Object.assign(this.data, this.populateBaseInfo());
    }
    if (
      this.domains !== undefined &&
      this.data !== "" &&
      this.userInstructionsRecommend &&
      this.userInstructionsAnalyse
    ) {
      this.changeMode(this.initialMode);
    }
  }

  private populateBaseInfo(): { [k: string]: VCloudAiSummaryData } {
    const d: { [k: string]: VCloudAiSummaryData } = {};
    if (d.currentURL === undefined) {
      d.currentURL = location.pathname;
    }
    if (d.csp === undefined) {
      const maybeCsp = location.pathname
        .split("/")
        .filter(Boolean)
        .map((s) => s.trim().toLowerCase())
        .find((s) => CLOUD_PROVIDERS_ALL.includes(s as CloudProvider));
      if (maybeCsp) {
        d.csp = maybeCsp;
      }
    }

    const isVCS = location.pathname.includes("vcloudsmart");
    const IsV2 = location.pathname.includes("v2");

    if (d.reportType === undefined && isVCS && IsV2) {
      const maybeReportType = location.pathname
        .split("/")
        .filter(Boolean)
        .map((s) => s.trim())
        .filter((v) => v !== "v2" && v !== "vcloudsmart")[1];
      if (maybeReportType) {
        d.reportType = maybeReportType;
      }
    }
    if (d.reportName === undefined && isVCS && IsV2) {
      const maybeReportName = location.pathname
        .split("/")
        .filter(Boolean)
        .map((s) => s.trim())
        .filter((v) => v !== "v2" && v !== "vcloudsmart")[2];
      if (maybeReportName) {
        d.reportName = maybeReportName;
      }
    }
    return d;
  }

  private inferDomains(): QueryDomain[] {
    const pathname: string = location.pathname.toLowerCase().trim();
    if (pathname.includes("cost")) {
      return ["billing"];
    } else if (
      pathname.includes("asset") ||
      pathname.includes("resource") ||
      pathname.includes("csb") ||
      pathname.includes("finops")
    ) {
      return ["assets"];
    } else if (pathname.includes("recommendation") || pathname.includes("saving")) {
      return ["recommendations"];
    } else {
      return DOMAINS;
    }
  }

  public setCreativity(c: Creativity): void {
    this.creativityModified = true;
    this.creativity = c;
    this.modelConfig.top_p = MODEL_CONFIG.top_p[c];
    this.modelConfig.temperature = MODEL_CONFIG.temperature[c];
    this.rerender();
  }

  private inferCloudProviders(): CloudProvider[] {
    const pathname: string = location.pathname.toLowerCase().trim();
    const providers: CloudProvider[] = pathname
      .split("/")
      .filter(Boolean)
      .filter((csp) => CLOUD_PROVIDERS_ALL.includes(csp.trim() as CloudProvider)) as CloudProvider[];
    if (providers.length === 0) {
      return CLOUD_PROVIDERS_ALL;
    } else {
      return providers;
    }
  }

  private get dataStringified(): string {
    if (typeof this.data === "string") {
      return this.data;
    } else {
      return JSON.stringify(this.data, null, 2);
    }
  }

  private getPrompt(): Observable<string> {
    let baseInstructions: string;

    if (this.mode === "analyse") {
      baseInstructions = this.userInstructionsAnalyse;
    } else if (this.mode === "recommend") {
      baseInstructions = this.userInstructionsRecommend;
    } else {
      throw new Error("Invalid mode");
    }

    const localeInfo: string[] = this.llm.formatLocaleInfo("casual_interactions");

    if (localeInfo.length > 0) {
      baseInstructions = `
${baseInstructions}

# Locale Information

${localeInfo.join("\n")}
`.trim();
    }

    const userPreferences: string[] = this.llm.describeUserPrefs();

    if (userPreferences.length > 0) {
      baseInstructions = `
${baseInstructions}

# User Preferences

You may use these user preferences to tailor the response to the user:

${userPreferences.join("\n")}

NOTE: disregard any preferences for cloud provider, always prioritise data provided below and the current page.

`.trim();
    }

    const cloudProviders: CloudProvider[] = this.llm.getCloudProvidersForPage();
    const domains: QueryDomain[] = this.llm.getDomainsForPage();
    const currentPageDescription = this.llm.describeCurrentPage(domains);

    if (currentPageDescription.length > 0) {
      baseInstructions = `
${baseInstructions}

# Current Page

${cloudProviders.length > 0 ? `This page is about ${cloudProviders.join(", ")} cloud.` : ""}

${domains.length > 0 ? `This page belongs to the ${domains.join(", ")} domains.` : ""}

${currentPageDescription.join("\n")}
`.trim();
    }

    const pageVisits = this.llm.describeRecentPageVisits(cloudProviders);

    if (pageVisits) {
      baseInstructions = `
${baseInstructions}

# Recently Visited Pages

${pageVisits}
`.trim();
    }

    return this.llm.describeRecentQuestions(this.cloudProviders ?? CLOUD_PROVIDERS_ENABLED).pipe(
      map((recentQuestions: string) => {
        return `
${baseInstructions}

# Recent Questions to VCloud AI        

To better understand the needs of the user you may consider the questions they asked recently. In some cases you may want to gently steer the direction of the response in such a way that it relates to the previously asked questions.

${recentQuestions}

`.trim();
      }),
      map((baseInstructions: string) => {
        if (this.data) {
          return `
      
${baseInstructions}

# Data to ${this.mode.slice(0, 1).toUpperCase()}${this.mode.slice(1)}
${this.dataSummary ? "\n" + this.dataSummary : ""}

\`\`\`${typeof this.data === "string" ? "" : "json"}
${this.dataStringified}
\`\`\`
`.trim();
        } else {
          return baseInstructions;
        }
      }),
      catchError((e) => {
        this.log.error(e);
        return of("");
      })
    );
  }

  public renderSummary(): Observable<LLMResponse> {
    return this.getPrompt().pipe(
      mergeMap((prompt) => {
        return this.llm.llmQuery(
          prompt,
          [],
          this.model,
          "analyse_recommend",
          this.modelConfig,
          this.cloudProviders,
          this.domains,
          "N/A"
        );
      }),
      catchError((e) => {
        this.log.error(e);
        return of({ Response: "Unable to process this request." });
      })
    );
  }

  public rerender(): void {
    this.aiSummary = undefined;

    setTimeout(async () => {
      this.loadingPhraseIdx = 0;
      const delay = this.mode === "recommend" ? 2000 : 1000;
      while (!this.aiSummary && this.loadingPhraseIdx !== LOADING_PHRASES.length - 1) {
        await this.sleep(delay);
        this.loadingPhraseIdx++;
      }
      this.timeouts.forEach((s) => clearTimeout(s));
      this.timeouts = [];
    });

    this.ngOnDestroy();

    const currentPageTitle: string = location.pathname
      .split("/")
      .slice(-2)
      .filter(Boolean)
      .map((w) => this.looker.prettifyAttribute(w.replace(DASH_REGEX, " ")))
      .join(": ");

    this.subscriptions.push(
      this.renderSummary().subscribe((res) => {
        const currentURL = `[${location.pathname}](${location.pathname})`;
        let s = res?.Response ?? "";
        while (s.includes(currentURL)) {
          s = s.replace(currentURL, `__${currentPageTitle}__`);
        }
        this.aiSummary = convertMarkdownToHTML(s);
        this.loadingPhraseIdx = 0;
      })
    );
  }

  public changeMode(mode: AiMode): void {
    if (this.title === DEFAULT_TITLE_ANALYSIS || this.title === DEFAULT_TITLE_RECOMMENDATIONS) {
      if (mode === "analyse") {
        this.title = DEFAULT_TITLE_ANALYSIS;
      } else {
        this.title = DEFAULT_TITLE_RECOMMENDATIONS;
      }
    }
    this.mode = mode;
    this.rerender();
  }

  public copy(text: string): void {
    this.clipboard.copy(this.llm.convertHTMLToMarkdown(text));
  }

  public expandCollapse(): void {
    this.expanded = !this.expanded;
  }

  private sleep(milliseconds: number): Promise<unknown> {
    return new Promise((resolve) => {
      this.timeouts.push(setTimeout(resolve, milliseconds) as unknown as number);
    });
  }

  public loadingPhraseIdx: number = 0;
  public readonly LOADING_PHRASES: string[] = LOADING_PHRASES;
}
