<div class="p-2">
  <div class="w-100 d-flex flex-row justify-content-between bg-light py-2 px-4 rounded">
    <h3 style="top: 3px" class="h3 position-relative">{{ title }}</h3>

    <div [class.invisible]="loading" id="ai-modes" class="d-none d-sm-flex flex-row justify-content-end">
      <button
        tooltip="Describe trends and patterns in the data"
        (click)="changeMode('analyse')"
        [disabled]="mode === 'analyse'"
        class="btn mode-btn mr-2 py-0 px-2"
      >
        Analyze
      </button>
      <button
        tooltip="Recommend next steps based on insights and patterns in the data"
        [disabled]="mode === 'recommend'"
        (click)="changeMode('recommend')"
        class="btn mode-btn mr-4 py-0 px-2"
      >
        Recommend
      </button>

      <div class="d-none d-lg-block">
        <app-vficon
          [class.invisible]="loading"
          (click)="copy(aiSummary)"
          tooltip="Copy generated text"
          class="clickable mr-4 position-relative"
          size="20px"
          iconPack="source-system-icons"
          iconName="copy"
        ></app-vficon>

        <app-vficon
          [class.invisible]="loading"
          (click)="rerender()"
          tooltip="Regenerate AI analysis (retry)"
          class="clickable mr-4 position-relative"
          size="20px"
          iconPack="source-system-icons"
          iconName="refresh"
        ></app-vficon>
      </div>

      <ng-container *ngIf="isExpandable" [ngSwitch]="expanded">
        <app-vficon
          *ngSwitchCase="true"
          [class.invisible]="loading"
          (click)="expandCollapse()"
          tooltip="Expand/collapse"
          class="clickable position-relative"
          size="20px"
          iconPack="source-system-icons"
          iconName="chevron-up"
        ></app-vficon>
        <app-vficon
          *ngSwitchCase="false"
          [class.invisible]="loading"
          (click)="expandCollapse()"
          tooltip="Expand/collapse"
          class="clickable position-relative"
          size="20px"
          iconPack="source-system-icons"
          iconName="chevron-down"
        ></app-vficon>
      </ng-container>
    </div>
  </div>

  <div [isAnimated]="true" [collapse]="!expanded">
    <div class="generatedHtml mt-4 mb-2" style="font-size: 13px" *ngIf="aiSummary" [innerHTML]="aiSummary"></div>

    <img
      style="max-height: 100px"
      id="vf-loader"
      class="d-block mx-auto mt-4"
      *ngIf="loading"
      src="/assets/images/spinner_logo.svg"
      alt="spinner"
    />

    <p *ngIf="loading" class="text-center">
      {{ LOADING_PHRASES[this.loadingPhraseIdx] }}
    </p>

    <ng-container *ngIf="!loading">
      <hr />

      <div class="d-flex flex-row w-100 justify-content-between pt-2 pb-3 pb-lg-0">
        <div class="d-flex flex-row justify-content-between align-items-center">
          <div class="d-flex flex-row justify-content-start align-items-center">
            <button
              tooltip="NOTE: low values of creativity offer conservative but potentially uninteresting responses"
              [disabled]="creativity === 'LOW'"
              (click)="this.setCreativity('LOW')"
              class="btn btn-sm btn-secondary mr-2 py-1 px-2"
            >
              Conservative
            </button>
            <button
              [disabled]="creativity === 'HIGH'"
              tooltip="NOTE: the higher the creativity the more likely the information is to be incorrect"
              (click)="this.setCreativity('HIGH')"
              class="btn btn-sm btn-secondary py-1 px-2"
            >
              Creative
            </button>
          </div>
          <p class="disclaimer pl-4 text-right w-100 m-0">
            <strong>NOTE</strong>: VCloud AI may generate
            <a target="_blank" href="https://en.wikipedia.org/wiki/Hallucination_(artificial_intelligence)"
              >incorrect responses</a
            >. Always verify the information received from LLMs.
            <ng-container *ngIf="creativity === 'HIGH'"
              >Switch to <strong>Conservative</strong> to receive more reliable answers.</ng-container
            >
          </p>
        </div>
      </div>
    </ng-container>
  </div>
</div>
